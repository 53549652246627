<template>
  <div class="mapdiv">
    <VueMapbox
      :accessToken="accessKey"
      mapStyle="mapbox://styles/felixxxguilherme/cl1zxppj9001o15o25sp5o45a"
      height="100%"
      width="100%"
      pitch="0"
      ref="mapbox"
      :maxBounds="[-43.495, -20.343, -43.113, -20.156]"
      :interactive="true"
      :center="[-43.5, -20.15]"
    >
      <slot name="layers"> </slot>

      <div
        class="d-flex justify-center align-center"
        :class="{ scrollToTop__button: isNavVisible }"
        v-if="onMobile"
        :style="{
          'z-index': 250,
          top: '2em',
          position: 'absolute',
          left: 0,
          width: '100%',
        }"
      >
        <span
          style="font-size: 1em; width: 60px; height: 60px;"
          @click="isNavVisible = !isNavVisible"
          class="ma-0 d-flex flex-column justify-center align-center"
        >
          <v-icon @click="isNavVisible = !isNavVisible" small class="">
            {{ isNavVisible ? "mdi-arrow-up" : "mdi-arrow-down" }}
          </v-icon>
          <p class="ma-0">{{ isNavVisible ? "expandir" : "mostrar" }}</p>
          <p class="ma-0">{{ isNavVisible ? "mapa" : "menu" }}</p>
        </span>
      </div>

      <InitialLayers />

      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[0] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[0].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[1] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[1].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[2] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[2].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[3] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[3].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[4] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[4].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[5] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[5].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[6] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[6].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[7] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[7].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[8] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[8].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[9] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[9].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[10] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[10].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[11] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[11].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[12] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[12].properties.path)"
      >
      </vmLayer>
      <vmLayer
        :source="{ type: 'geojson', data: comunidade_geojson.features[13] }"
        type="symbol"
        :layout="comunidades.layout"
        :maxzoom="14"
        :minzoom="9"
        :zIndex="200"
        @click="flyToPage(comunidade_geojson.features[13].properties.path)"
      >
      </vmLayer>

      <VmImage
        :name="comunidades.iconName"
        :url="`/icons/${comunidades.iconName}-icon.png`"
      />

      <LineDefault :layer="rioGualaxo" />
    </VueMapbox>
  </div>
</template>

<script>
import InitialLayers from "@/modules/Mapa/layers/map/InitialLayers";
import LineDefault from "@/modules/Mapa/layers/map/LineDefault";

export default {
  components: { InitialLayers, LineDefault },
  name: "",
  data() {
    return {
      isNavVisible: false,
      accessKey: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      comunidade_geojson: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.1",
            geometry: {
              type: "Point",
              coordinates: [-43.4175, -20.2376],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_bento_rodrigues",
              nome: "Bento Rodrigues",
              subdistrit: "Bento Rodrigues",
              comunidade: "Bento Rodrigues",
            },
            bbox: [-43.4175, -20.2376, -43.4175, -20.2376],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.1",
            geometry: {
              type: "Point",
              coordinates: [-43.24213, -20.27379],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_mata_chaves",
              nome: "Mata dos Chaves",
              subdistrit: "Mata dos Chaves",
              comunidade: "Mata dos Chaves",
            },
            bbox: [-43.4175, -20.2376, -43.4175, -20.2376],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.2",
            geometry: {
              type: "Point",
              coordinates: [-43.2227, -20.2541],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_aguas_claras",
              nome: "Águas Claras",
              subdistrit: "Águas Claras",
              comunidade: "Águas Claras",
            },
            bbox: [-43.2227, -20.2541, -43.2227, -20.2541],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.3",
            geometry: {
              type: "Point",
              coordinates: [-43.2301, -20.3043],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_paracatu_de_baixo",
              nome: "Paracatu de Baixo",
              subdistrit: "Paracatu de Baixo",
              comunidade: "Paracatu de Baixo",
            },
            bbox: [-43.2301, -20.3043, -43.2301, -20.3043],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.4",
            geometry: {
              type: "Point",
              coordinates: [-43.1871, -20.2861],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_pedras",
              nome: "Pedras",
              subdistrit: "Pedras",
              comunidade: "Pedras",
            },
            bbox: [-43.1871, -20.2861, -43.1871, -20.2861],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.5",
            geometry: {
              type: "Point",
              coordinates: [-43.2018, -20.2998],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_borba",
              nome: "Borba",
              subdistrit: "Borba",
              comunidade: "Borba",
            },
            bbox: [-43.2018, -20.2998, -43.2018, -20.2998],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.7",
            geometry: {
              type: "Point",
              coordinates: [-43.1735, -20.2622],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_campinas",
              nome: "Campinas",
              subdistrit: "Campinas",
              comunidade: "Campinas",
            },
            bbox: [-43.1735, -20.2622, -43.1735, -20.2622],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.8",
            geometry: {
              type: "Point",
              coordinates: [-43.2979, -20.2664],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_ponte_do_gama",
              nome: "Ponte do Gama",
              subdistrit: "Ponte do Gama",
              comunidade: "Ponte do Gama",
            },
            bbox: [-43.2979, -20.2664, -43.2979, -20.2664],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.9",
            geometry: {
              type: "Point",
              coordinates: [-43.3601, -20.2573],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_bicas",
              nome: "Bicas",
              subdistrit: "Bicas",
              comunidade: "Bicas",
            },
            bbox: [-43.3601, -20.2573, -43.3601, -20.2573],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.10",
            geometry: {
              type: "Point",
              coordinates: [-43.4024, -20.2716],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_camargos",
              nome: "Camargos",
              subdistrit: "Camargos",
              comunidade: "Camargos",
            },
            bbox: [-43.4024, -20.2716, -43.4024, -20.2716],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.11",
            geometry: {
              type: "Point",
              coordinates: [-43.416, -20.1826],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_santa_rita",
              nome: "Santa Rita Durão",
              subdistrit: "Santa Rita Durão",
              comunidade: "Santa Rita Durão",
            },
            bbox: [-43.416, -20.1826, -43.416, -20.1826],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.12",
            geometry: {
              type: "Point",
              coordinates: [-43.2656, -20.3084],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_monsenhor",
              nome: "Monsenhor Horta",
              subdistrit: "Monsenhor Horta",
              comunidade: "Monsenhor horta",
            },
            bbox: [-43.2656, -20.3084, -43.2656, -20.3084],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.6",
            geometry: {
              type: "Point",
              coordinates: [-43.2494, -20.2988],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_paracatu_de_cima",
              nome: "Paracatu de Cima",
              subdistrit: "Paracatu de Cima",
              comunidade: "Paracatu de Cima",
            },
            bbox: [-43.2494, -20.2988, -43.2494, -20.2988],
          },
          {
            type: "Feature",
            id: "tab_geo_territorial_sub_distritos_ctb.13",
            geometry: {
              type: "Point",
              coordinates: [-43.18975, -20.323361],
            },
            geometry_name: "geom",
            properties: {
              path: "sobre_braunas",
              nome: "Braúnas",
              subdistrit: "Braúnas",
              comunidade: "Braúnas",
            },
            bbox: [-43.2494, -20.2988, -43.2494, -20.2988],
          },
        ],
      },
    };
  },

  computed: {
    comunidades() {
      return {
        name: "Subdistritos",
        categoria: "LayerComunidade",
        visible: true,
        visibleUI: true,
        colorUI: "#d4ecd5",
        pattern: "",
        iconName: "circle",
        componentName: "PointComunidades",
        description: "",
        downloadLink: "",
        zIndex: 300,
        props: {
          source: {
            type: "geojson",
            generateId: true,
            data: this.comunidade_geojson,
          },
        },
        layout: {
          visibility: "visible",
          "icon-image": "circle",
          "icon-size": 0.4,
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "text-allow-overlap": true,
          "text-anchor": "top",
          "text-offset": [0, 0.6],
          "text-field": "{subdistrit}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 18,
        },
      };
    },
    rioGualaxo() {
      return {
        name: "Rio Gualaxo do Norte",
        categoria: "recurso_hidrico",
        visible: true,
        visibleUI: true,
        colorUI: "#8BE7FC",
        pattern: "",
        iconName: "",
        componentName: "LineDefault",
        description: "",
        downloadLink: "",
        zIndex: 100,
        props: {
          source: {
            type: "geojson",
            generateId: true,
            data:
              "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_hidro_rio_gualaxo&maxFeatures=50&outputFormat=application%2Fjson",
          },
        },
        layout: {
          visibility: "visible",
        },
      };
    },

    onMobile() {
      return this.isMobile();
    },

    navVisibility() {
      return this.isNavVisible
        ? window.scrollTo(0, 0)
        : window.scrollTo(0, 100);
    },
  },

  created() {
    if (this.isMobile) {
      this.isNavVisible = false;
      window.scrollTo(0, 100);
    }
  },

  watch: {
    isNavVisible(newVal) {
      console.log(newVal);
      if (newVal == true) {
        window.scrollTo(0, 0);
      }
      if (newVal == false) {
        window.scrollTo(0, 100);
      }
    },
  },

  mounted() {
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
    } else {
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
  },

  methods: {
    featureselect: function(layer) {
      if (Array.isArray(layer) && layer[0] && layer[0].features.properties) {
        this.features = layer[0].features.properties;
      } else {
        this.features = false;
      }
    },

    flyToPage(route) {
      this.$router.push({ name: route });
    },

    scrollToTop() {
      this.isNavVisible = !this.isNavVisible;
    },

    isAndroid() {
      return navigator.userAgent.match(/Android/i);
    },
    isBlackBerry() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    isIOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    isOpera() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    isWindows() {
      return navigator.userAgent.match(/IEMobile/i);
    },
    isMobile() {
      return (
        this.isAndroid() ||
        this.isBlackBerry() ||
        this.isIOS() ||
        this.isOpera() ||
        this.isWindows()
      );
    },
  },
};
</script>

<style scoped>
.scrollToTop__button {
  animation: fade 1.5s infinite 200ms;
}
@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

.mapdiv {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: hidden;
}
</style>
