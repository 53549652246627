<template>

    
	<div class="">
		<vmLayer
		:source="layer.props.source"
		type="line"
		:paint="{ 'line-width': 9, 'line-color': '#4264fb'  }"
		@featureselect="featureselect"
		>
	</vmlayer>

	</div>

</template>

<script>
// 
 // 
export default {

	props: ['layer'],

	data() {
		return {
			features: false,
			opacity: 1
		}
	},	
	
	methods: {
		featureselect: function(layer) {
			if(Array.isArray(layer) && layer[0] && layer[0].features.properties) {
				this.features = layer[0].features.properties
			} else {
				this.features = false
			}
		}
	}

}
</script>

<style lang="stylus" scoped>


</style>
