<template>
  <div class="">
    <vmLayer
      :source="{ type: 'geojson', data: bicas }"
      type="symbol"
      :layout="bicas.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <vmLayer
      :source="{ type: 'geojson', data: bento_rodrigues }"
      type="symbol"
      :layout="bento_rodrigues.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <vmLayer
      :source="{ type: 'geojson', data: camargos }"
      type="symbol"
      :layout="camargos.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <vmLayer
      :source="{ type: 'geojson', data: santa_rita_durao }"
      type="symbol"
      :layout="santa_rita_durao.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <vmLayer
      :source="{ type: 'geojson', data: ponte_do_gama }"
      type="symbol"
      :layout="ponte_do_gama.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <vmLayer
      :source="{ type: 'geojson', data: monsenhor_horta }"
      type="symbol"
      :layout="monsenhor_horta.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <vmLayer
      :source="{ type: 'geojson', data: paracatu_de_baixo }"
      type="symbol"
      :layout="paracatu_de_baixo.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <vmLayer
      :source="{ type: 'geojson', data: paracatu_de_cima }"
      type="symbol"
      :layout="paracatu_de_cima.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <vmLayer
      :source="{ type: 'geojson', data: aguas_claras }"
      type="symbol"
      :layout="aguas_claras.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <vmLayer
      :source="{ type: 'geojson', data: borba }"
      type="symbol"
      :layout="borba.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <vmLayer
      :source="{ type: 'geojson', data: camponesa }"
      type="symbol"
      :layout="camponesa.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <vmLayer
      :source="{ type: 'geojson', data: campinas }"
      type="symbol"
      :layout="campinas.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <vmLayer
      :source="{ type: 'geojson', data: pedras }"
      type="symbol"
      :layout="pedras.layout"
      :maxzoom="14"
      :minzoom="9"
      z-index="200"
    >
    </vmLayer>

    <VmImage name="bicas" :url="`/init/bicas.png`" />

    <VmImage name="bento_rodrigues" :url="`/init/bento_rodrigues.png`" />

    <VmImage name="camargos" :url="`/init/camargos.png`" />

    <VmImage name="santa_rita_durao" :url="`/init/santa_rita_durao.png`" />

    <VmImage name="ponte_do_gama" :url="`/init/ponte_do_gama.png`" />

    <VmImage name="monsenhor_horta" :url="`/init/monsenhor_horta.png`" />

    <VmImage name="paracatu_de_baixo" :url="`/init/paracatu_de_baixo.png`" />

    <VmImage name="paracatu_de_cima" :url="`/init/paracatu_de_cima.png`" />

    <VmImage name="aguas_claras" :url="`/init/aguas_claras.png`" />

    <VmImage name="borba" :url="`/init/borba.png`" />

    <VmImage name="camponesa" :url="`/init/camponesa.png`" />

    <VmImage name="campinas" :url="`/init/campinas.png`" />

    <VmImage name="pedras" :url="`/init/pedras.png`" />
  </div>
</template>

<script>
//
//
export default {
  data() {
    return {
      bicas: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Bicas",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.3601, -20.2473],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "bicas",
          "icon-size": 0.15,
          "icon-allow-overlap": true,
        },
      },
      bento_rodrigues: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Bento Rodrigues",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.4175, -20.227],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "bento_rodrigues",
          "icon-size": 0.15,
          "icon-allow-overlap": true,
        },
      },

      camargos: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Camargos",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.3924, -20.2716],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "camargos",
          "icon-size": 0.15,
          "icon-allow-overlap": true,
        },
      },

      santa_rita_durao: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Santa Rita Durão",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.416, -20.1726],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "santa_rita_durao",
          "icon-size": 0.15,
          "icon-allow-overlap": true,
        },
      },

      ponte_do_gama: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Ponte do Gama",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.2979, -20.2564],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "ponte_do_gama",
          "icon-size": 0.15,
          "icon-allow-overlap": true,
        },
      },

      monsenhor_horta: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Monsenhor Horta",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.2704, -20.3264],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "monsenhor_horta",
          "icon-size": 0.2,
          "icon-allow-overlap": true,
        },
      },

      paracatu_de_baixo: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Paracatu de Baixo",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.2201, -20.323],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "aguas_claras",
          "icon-size": 0.15,
          "icon-allow-overlap": true,
        },
      },
      paracatu_de_cima: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Paracatu de Cima",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.2509, -20.2912],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "",
          "icon-size": 0.1,
          "icon-allow-overlap": true,
        },
      },
      aguas_claras: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Águas Claras",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.2227, -20.2441],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "paracatu_de_baixo",
          "icon-size": 0.15,
          "icon-allow-overlap": true,
        },
      },
      borba: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Borba",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.178, -20.2781],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "pedras",
          "icon-size": 0.15,
          "icon-allow-overlap": true,
        },
      },
      camponesa: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Camponesa",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.208, -20.2898],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "camponesa",
          "icon-size": 0.12,
          "icon-allow-overlap": true,
        },
      },
      campinas: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Campinas",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.1705, -20.249],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "borba",
          "icon-size": 0.1,
          "icon-allow-overlap": true,
        },
      },
      pedras: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              nome: "Pedras",
            },
            geometry: {
              type: "Point",
              coordinates: [-43.1294059753418, -20.29279143428364],
            },
          },
        ],
        layout: {
          visibility: "visible",
          "icon-image": "",
          "icon-size": 0.2,
          "icon-allow-overlap": true,
        },
      },
    };
  },
};
</script>

<style lang="stylus" scoped></style>
